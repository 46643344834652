

// GRID CONFIGURATION
$grid-columns: 18;
$grid-gutter-width: 22px;

$grid-width-xs: 8px;
$grid-width-sm: 20px;
$grid-width-md: 24px;

$outer-gutter-xs: 20px;
$outer-gutter-sm: 34px;
$outer-gutter-md: 38px;



// FONTS
$font-default: 'PublicoText-Roman';
$font-heading1: 'PublicoHeadline-Light';
$font-heading2: 'PublicoHeadline-Roman';


// COLORS
$blue: #150f96; // AKA dark blue
$cyan: #01c1d6;
$pink: #ff0198;
$purple: #6e27c5;
$red: #f1352b;
$orange: #ff6600;
$yellow: #ffb617;
$green: #7acb00;


$color-cycle:      $blue  $cyan    $pink    $purple  $red     $orange  $yellow  $green;
$color-cycle-pair: $cyan  $purple  $purple  $cyan    $orange  $red     $yellow  $blue;
$color-cycle-hero: $pink  $cyan    $yellow  $orange  $pink    $cyan    $yellow  $orange;

$color-placeholder: #666;


$darker-blue: #0e0a63;
$midnight: #171636;

$dark-gray: #333333;
$gray: #c5c5c5;
$mid-gray: #4c4e53;
$lighter-gray: #f4f4f4;
$light-gray: #ebebeb;
$white: #ffffff;



