

.product-promo-primary {
  position: relative;
  overflow: hidden;
  background: $lighter-gray;
  padding: 0 $outer-gutter-xs;
  @include respond-to-sm {
    padding: 0 $outer-gutter-sm;
  }
  @include respond-to-md {
    padding: 0 ($outer-gutter-md + (2 * $grid-width-md));
  }

  .items {
    margin-top: 30px;
    @include make-row();
  }

  .product-promo-item {
    margin-bottom: 30px;
    @include make-sm-column(9);
  }

  .tile {
    position: relative;
    background: $white;
    // border-top: 4px solid $color-placeholder;
    padding: 34px (2 * $grid-width-xs);
    @include respond-to-sm {
      padding: 34px $grid-width-sm 40px;
    }
    @include respond-to-md {
      padding: 44px $grid-width-md 40px;
    }

    &:before {
      content:"";
      position:absolute;
      display: block;
      background: $color-placeholder;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
    }


    .title {
      font: 24px/26px $font-heading1;
      margin-bottom: 20px;
      @include respond-to-sm {
        margin-bottom: 30px;
      }

      .fa-angle-right {
        display: none;
      }

    }

    .text {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 20px;
      @include respond-to-sm {
        margin-bottom: 40px;
      }
    }
  }
}


.product-promo-secondary {
  padding: 0 $outer-gutter-xs;
  @include respond-to-sm {
    padding: 0 ($outer-gutter-sm + (2*$grid-width-sm));
  }
  @include respond-to-md {
    padding: 0 ($outer-gutter-md + (2*$grid-width-md)) 0 ($outer-gutter-md + (10*$grid-width-md));
  }

  .items {
    margin-top: 30px;
  }

  .product-promo-item {
    margin-bottom: 30px;
  }

  .tile {
    position: relative;
    background: $white;
    border: 1px solid $light-gray;
    border-top: 0;
    padding: 24px (4*$grid-width-xs) 30px;
    @include respond-to-sm {
      padding: 34px (2*$grid-width-sm) 40px;
    }
    @include respond-to-md {
      padding: 34px $grid-width-md 50px;
    }

    &:before {
      content:"";
      position:absolute;
      display: block;
      background: $color-placeholder;
      top: 0;
      left: -1px;
      right: -1px;
      height: 4px;
    }


    .title {
      font: 24px/28px $font-heading2;
      margin-bottom: 20px;
      @include respond-to-sm {
        margin-bottom: 30px;
      }
    }

    .text {
      font-size: 14px;
      line-height: 1.25;
    }

    .cta {
      display: block;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      @include hide-text;
    }

  }
}


.product-promo-tertiary {
  background: $lighter-gray;
  padding: 0 $outer-gutter-xs;
  @include respond-to-sm {
    padding: 0 ($outer-gutter-sm + (2*$grid-width-sm));
  }
  @include respond-to-md {
    padding: 0 ($outer-gutter-md + (2*$grid-width-md)) 0 ($outer-gutter-md + (10*$grid-width-md));
  }
  @include clearfix;

  .items {
    margin-top: 20px;
    @include respond-to-sm {
      margin-top: 30px;
    }
  }

  .product-promo-item {
    margin-bottom: 20px;
    @include respond-to-sm {
      margin-bottom: 30px;
    }
  }

  .tile {
    position: relative;
    background: $white;
    padding: 30px ((2*$grid-width-xs)+4) 30px 2*$grid-width-xs;
    @include respond-to-sm {
      padding: 30px ((2*$grid-width-sm)+4) 40px 2*$grid-width-sm;
    }
    @include respond-to-md {
      padding: 30px (($grid-width-md)+4) 40px 2*$grid-width-md;
    }

    &:before {
      content:"";
      position:absolute;
      display: block;
      background: $color-placeholder;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
    }


    .title {
      font: 16px/22px $font-heading2;
      @include respond-to-sm {
        font: 20px/22px $font-heading2;
      }
    }

    .text {
      display: none;
    }

    .cta {
      display: block;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      @include hide-text;
    }

  }
}


@for $i from 1 through 8 {
  .product-promo-item.colorize-#{$i} {
    .tile:before { background-color: nth($color-cycle, $i); }
  }
}


