
.video {
  // margin: 40px 0;
  @include respond-to-sm {
    // margin: 40px 0;
  }
  @include respond-to-md {
    // margin: 50px 0;
  }

  .tile {
    @include clearfix;
  }

  .image, .copy {
    @include make-sm-column(9);
    height: 274px;
    @include respond-to-sm {
      height: 325px;
    }
    @include respond-to-md {
      height: 436px;
    }
  }

  .image {
    background: transparent center center no-repeat;
    background-size: cover;

    button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .copy {
    background-color: $color-placeholder;
    color: $white;
    padding: 40px $outer-gutter-xs;
    @include respond-to-sm {
      padding: 50px 2*$outer-gutter-sm;
    }
    @include respond-to-md {
      padding: 100px 2*$outer-gutter-md;
    }
  }

  .eyebrow {
    font-size: 14px;
    margin-bottom: 30px;
    @include respond-to-sm {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }

  .title {
    font: 26px/1.2 $font-heading2;
    margin-bottom: 20px;
    @include respond-to-sm {
      font-size: 36px;
    }
  }

  .text {
    font-size: 14px;
  }

  .lightbox-image {
    max-width: 100%;
    @include respond-to-sm {
      max-width: $screen-sm-min - $outer-gutter-sm*2;
    }
    @include respond-to-md {
      max-width: $screen-md-min - $outer-gutter-md*2;
    }
  }

  .modal-lightbox-content {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  .video-player {
    display: block;
    position: relative;
    width: 100%;
  }

}





@for $i from 1 through 8 {
  .video.colorize-#{$i} {
    .copy { background-color: nth($color-cycle, $i); }
  }
}



