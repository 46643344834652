
header {
  position: relative;
  background: $color-placeholder center center no-repeat;
  background-size: cover;
  color: $white;

  .navbar {
    margin-bottom: 0;
    border: 0;
  }

  .top-navbar {
    background-color: rgba(0,0,0, 0.4);
    padding: 10px $outer-gutter-xs;
    @include respond-to-sm {
      padding: 10px $outer-gutter-sm;
    }
    @include respond-to-md {
      padding: 10px $outer-gutter-md;
    }
    font: 13px/15px $font-heading2;
    color: $gray;

    @include clearfix;
    display: none;
    @include respond-to-sm {
      display: block;
    }

    .links {
      float: right;
      margin: 0;

      li {
        float: left;
        margin: 0 10px;
      }

      .separator {
        opacity: 0.7;
      }

      a {
        color: $gray !important;
        opacity: 0.7;
        padding: 4px 0;
        &:hover {
          opacity: 1;
          border-bottom: 2px solid $gray;
        }
        text-decoration: none;
      }
    }
  }


  .main-navbar {
    position: relative;
    padding: 0 $outer-gutter-xs;
    height: 60px;
    @include respond-to-sm {
      height: 90px;
    }
    overflow: hidden;
    @include respond-to-sm {
      padding: 0 $outer-gutter-sm;
    }
    @include respond-to-md {
      padding: 0 $outer-gutter-md;
    }
    font: 14px/1.2 $font-heading2;
    @include respond-to-md {
      font-size: 16px;
    }
    @include clearfix;

    .logo {
      position: absolute;
      background: url(../img/logo.png) center center no-repeat;
      width: 144px;
      height: 16px;
      top: 50%;
      margin-top: -9px;
      display: block;
      text-indent: -999999px;
      @include respond-to-sm {
        margin-top: -11px;
      }
    }

    .links {
      float: right;
      margin: 0;
      height: 100%;
      display: none;
      // max-width: 500px;
      @include respond-to-sm {
        display: table;
      }
      // @include respond-to-md {
      //   max-width: none;
      // }

      li {
        display: table-cell;
        padding: 0 10px;
        vertical-align: middle;
      }

      .separator {
        opacity: 0.7;
      }

      a {
        color: $white !important;
        opacity: 0.7;
        padding: 6px 0;
        &:hover {
          opacity: 1;
          border-bottom: 2px solid $white;
        }
        text-decoration: none;
      }

      .search-link:hover {
        border-bottom: none;
      }

    }

    .navbar-toggle {
      border: 0;
      margin: 0;
      padding: 0 20px;
      margin-right: -20px;
      border-radius: 0;
      height: 100%;


      .icon-bar {
        background-color: #ffffff;
        border-radius: 0;
        transform-origin: 12% 50%;

        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        transition: all 0.5s;
      }

      &.collapsed .icon-bar {
        transform: none;
        opacity: 1;
      }
    }
  }


  .search-nav-bar {
    padding: 0 $outer-gutter-sm;
    @include respond-to-md {
      padding: 0 $outer-gutter-md;
    }
    @media (max-width: $screen-sm-min - 1) {
      display: none !important;
    }

    .search-bar-box {
      padding: 10px 0 10px;
    }

    .search-bar {
      // margin-top: 40px;
    }
  }


  .menu {
    padding: 0 $outer-gutter-xs;
    color: $white;
    overflow: auto;

    .search-bar {
      margin-top: 10px;
      margin-bottom: 50px;
    }



    .primary-links {
      margin-bottom: 50px;
      font-size: 18px;
      line-height: 1;

      .link {
        margin-bottom: 25px;
      }

      a {
        color: $white !important;
        text-decoration: none;
      }
      .separator {
        height: 25px;
      }
    }

    .secondary-links {
      margin-bottom: 50px;
      font-size: 14px;
      line-height: 1;

      .link {
        margin-bottom: 22px;
      }

      a {
        color: $white !important;
        text-decoration: none;
      }
    }
  }
}


header nav {
  background-color: rgba(0,0,0,0);
  transition: background-color 0.5s;
}

header.expanded nav {
  background-color: rgba(0,0,0,0.5);
}


@for $i from 1 through 8 {
  header.colorize-#{$i} {
    background-color: nth($color-cycle, $i);
  }
}



