
.message-tile {

  background-color: $lighter-gray;
  padding: 25px ($outer-gutter-xs + ($grid-width-xs * 2)) 50px;
  @include respond-to-sm {
    padding: 50px ($outer-gutter-sm + ($grid-width-sm * 2)) 70px;
  }
  @include respond-to-md {
    padding: 50px ($outer-gutter-md + ($grid-width-md * 2)) 90px;
  }

  .title {
    font: 20px/1.1 $font-heading1;
    margin-bottom: 10px;
    @include respond-to-sm {
      font-size: 28px;
      margin-bottom: 20px;
    }
    @include respond-to-md {
      font-size: 36px;
      margin-bottom: 20px;
    }
  }

  .text {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 30px;
    @include respond-to-sm {
      font-size: 16px;
      margin-bottom: 35px;
    }
    @include respond-to-md {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
}
