
.cta-module {

  background: center center no-repeat;
  background-size: cover;
  color: $white;

  @include respond-to-sm {
    padding: 50px 0 0 ($outer-gutter-sm + 4*$grid-width-sm);
  }
  @include respond-to-md {
    padding: 50px 0 0 ($outer-gutter-md + 8*$grid-width-md);
  }

  .content {
    padding: 40px $outer-gutter-xs;
    @include respond-to-sm {
      padding: 50px $outer-gutter-sm 50px 2*$grid-width-sm;
    }
    @include respond-to-md {
      padding: 50px $outer-gutter-md 50px 2*$grid-width-md;
    }
  }

  .title {
    font: 26px/1 $font-heading1;
    margin-bottom: 30px;
    @include respond-to-sm {
      font-size: 36px;
      margin-bottom: 30px;
    }
  }

  .text {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 30px;
    @include respond-to-sm {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }

  .ctas {
    padding: 0;
    margin: 0;
    list-style: none;
    @include clearfix;
  }

  .cta {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    @include respond-to-sm {
      float: left;
      margin-right: 15px;
      margin-bottom: 0;
    }

    .btn {
      display: block;
      position: relative;
      text-align: left;
      @include respond-to-sm {
        text-align: center;
      }

      .fa {
        float: right;
        margin-top: 10px;
      }
    }
  }


}


@for $i from 1 through 8 {
  .cta-module.colorize-#{$i} {
    .content { background-color: nth($color-cycle, $i); }
  }
}



