

.bios {
  background: $lighter-gray;
  padding: 40px $outer-gutter-xs 20px;
  @include respond-to-sm {
    padding: 50px ($outer-gutter-sm + 4*$grid-width-sm) 25px;
  }
  @include respond-to-md {
    padding: 50px ($outer-gutter-md + 2*$grid-width-md) 25px;
  }

  .items {
    @include make-row();
  }

  .bio-item {
    margin-bottom: 20px;
    @include make-sm-column(9);
    @include make-md-column(6);
  }

  .tile {
    position: relative;
    background: $white;

    .photo {
      height: 200px;
      background: $color-placeholder center center no-repeat;
      background-size: cover;
    }

    .text {
      padding: 20px 2*$grid-width-xs 40px;
      overflow: hidden;
      @include respond-to-sm {
        padding: 25px $grid-width-sm;
        height: 240px;
      }
    }

    .name {
      font: 20px/1.2 $font-heading1;
      margin-bottom: 20px;
      @include respond-to-sm {
        font-size: 25px;
      }
    }

    .title {
      font-size: 14px;
      line-height: 1.3;
      margin-bottom: 20px;
    }

    .action {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
