
* {
  text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


html, body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  font-size: 16px;
  line-height: 1.25em;
  font-family: $font-default;
  color: $text-color;
}

.body-container {
  padding: 0;
  position: relative;
}

hr {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #dddddd;
}




// Headings
// ====================

h1 {
  font-family: $font-heading1;
  font-size: 54px;
}

h2 {
  font-family: $font-heading1;
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

.chapter-heading {
  font: 26px/1 $font-heading1;
  margin: 40px 0 20px;
  @include respond-to-sm {
    font-size: 36px;
    margin: 50px 0 40px;
  }
}



// Links and Buttons
// ====================
a:focus {
  outline: none;
}

.btn {
  outline: none !important;
  color: $dark-gray !important;
  border: 1px solid $dark-gray;
  border-radius: 0;
  font: 14px/1 $font-default;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  background: transparent;

  @include respond-to-sm {
    font-size: 16px;
    height: 45px;
    line-height: 45px;
  }

  &:hover {
    opacity: 0.30;
    color: $dark-gray;
  }

  .fa-angle-right {
    margin-left: 10px;
  }
}


.btn-light {
  color: $white !important;
  border-color: $white;
}


.btn-search {
  color: $white !important;
  background-color: $blue;
  border-color: $blue;
}

.btn-go {
  color: $white !important;
  background-color: $cyan;
  border-color: $cyan;
}

.btn-compact {
  height: 55px;
  line-height: 55px;
  width: 55px;
}



// FORMS
// ====================

textarea, input {
  font: 14px/1.2 $font-default;
  color: $dark-gray;
  border: 1px solid $light-gray !important;
  border-radius: 0 !important;
  @include respond-to-sm {
    font-size: 16px;
  }

  outline: none !important;
  -webkit-appearance:none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;

  &:focus {
    border-color: $cyan !important;
  }


}

input[type='text'],
input[type='email'] {
  height: 40px;
  @include respond-to-sm {
    height: 45px;
  }

}

.input-icon {
  color: $gray;
  z-index: 1;
  float: left;
  width: 0px;
  pointer-events: none;
  background: none;
  overflow: visible;
  line-height: 40px;
  @include respond-to-sm {
    line-height: 45px;
  }
  .icon {
    margin-left: 10px;
    pointer-events: none;
    background: none;
  }
}

.input-icon + input {
  padding-left: 32px;
}


label {
  font: 14px/1.2 $font-default;
  margin-bottom: 20px;
}



// MODALS
// ====================

.modal-lightbox {
  text-align: center;
  white-space: nowrap;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; // remove space
  }
}

.modal-lightbox-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}





// Component page stuff (move this somewhere)
// ===========================================

.components-nav {
  position: relative;
  height: 50px;
  color: #fff;
  padding: 0;

  nav {
    position: fixed;
    z-index: 9999;
    padding: 0;
    background: rgba(#333, 0.9);
  }

  .menu-toggle {
    display: block;
    height: 50px;
    color: #fff !important;
    text-decoration: none !important;
    line-height: 50px;
    padding: 0 15px;
    font-size: 24px;

    &:hover {
      background: rgba(#000, 0.2);
    }

    &:after {
      display: inline-block;
      line-height: inherit;
      font-family: FontAwesome;
      content: '\00f106';
      float: right;
    }

    &.collapsed:after {
      content: '\00f107';
    }
  }

  .menu {
    background: rgba(#333, 0.9);
    width: 100%;
    border: 0;
    border-radius: 0;
    margin: 0;
  }

  .menu-item {

    .title {
      font-size: 18px;
      color: #fff;
    }

    .subtitle {
      font-size: 14px;
      color: #bbb;
      font-style: italic;
    }

    a:hover {
      background: rgba(#000, 0.2);
    }
  }

}

.component-box {
  margin-bottom: 80px;
}

.component-header {
  padding-top: 50px;
  margin-bottom: 30px;
  border-bottom: 1px solid #bbb;
  position: relative;

  h3 {
    color: #666;
    font-style: italic;
  }
}

