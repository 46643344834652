
.infographic {
  // margin: 40px 0;
  @include respond-to-sm {
    // margin: 40px 0;
  }
  @include respond-to-md {
    // margin: 50px 0;
  }

  .tile {
    @include clearfix;
  }

  .image, .copy {
    @include make-sm-column(9);
    height: 274px;
    @include respond-to-sm {
      height: 325px;
    }
    @include respond-to-md {
      height: 436px;
    }
  }

  .image {
    background: transparent center center no-repeat;
    background-size: cover;

    button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .copy {
    background-color: $color-placeholder;
    color: $white;
    padding: 40px $outer-gutter-xs;
    @include respond-to-sm {
      padding: 40px 2*$outer-gutter-sm;
    }
    @include respond-to-md {
      padding: 60px 2*$outer-gutter-md;
    }
  }

  .eyebrow {
    font-size: 12px;
    line-height: 1.1;
    margin-bottom: 20px;
    @include respond-to-sm {
      margin-bottom: 30px;
    }
  }

  .title {
    font: 26px/1.1 $font-heading2;
    @include respond-to-sm {
      font-size: 36px;
    }
  }

  .lightbox-image {
    max-width: 100%;
    @include respond-to-sm {
      max-width: $screen-sm-min - $outer-gutter-sm*2;
    }
    @include respond-to-md {
      max-width: $screen-md-min - $outer-gutter-md*2;
    }
  }
}


@for $i from 1 through 8 {
  .infographic.colorize-#{$i} {
    .copy { background-color: nth($color-cycle, $i); }
  }
}



