
// Responsive design helpers
//
//

@mixin respond-to-sm {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin respond-to-md {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin respond-to-lg {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}



// Hide Text
@mixin hide-text {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

// Edit Mode - Displays Container Dimensions with light grey background
@mixin edit {
  * {
    background: rgba(0, 0, 0, .05);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#10000000, endColorstr=#10000000);
  }
}