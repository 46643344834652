
$header-height-xs: 60px;
$header-height-sm: 125px;


.hero {

  .inner {
    position: relative;
    color: $white;
    padding: 40px $outer-gutter-xs 50px;
    @include respond-to-sm {
      padding: 50px $outer-gutter-sm 70px;
    }
    @include respond-to-md {
      padding: 50px ($outer-gutter-md + (2 * $grid-width-md)) 100px;
    }
  }


  .back-nav {
    font: 16px/1 $font-heading1;
    color: $white !important;
    margin-bottom: 35px;
    display: none;
    @include respond-to-sm {
      display: inline-block;
    }
    @include respond-to-md {
      margin-bottom: 40px;
    }
  }

  .content {
    position: relative;
  }

  .eyebrow {
    font-size: 14px;
    margin-bottom: 20px;
  }

  h1 {
    font: 26px/1.2 $font-heading1;
    margin-top: 0;
    margin-bottom: 30px;
    @include respond-to-sm {
      font-size: 42px;
      margin-bottom: 35px;
    }
    @include respond-to-md {
      font-size: 54px;
      margin-bottom: 40px;
    }
  }

  .text {
    font-size: 14px;
    line-height: 1.3;
    @include respond-to-sm {
      font-size: 16px;
    }
    @include respond-to-md {
      font-size: 20px;
    }
  }

  .points {
    list-style: none;
    padding-left: 0;

    li {
      position: relative;
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 20px;
      margin-left: 1.6em;
      &:last-child {
        margin-bottom: 0;
      }

      @include respond-to-sm {
        font-size: 18px;
        margin-bottom: 25px;
      }
      @include respond-to-md {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }

    li:before {
      content: '';
      display: block;
      position: absolute;
      top: 0.5em;
      margin-top: -1px;
      left: -1.6em;
      height: 2px;
      width: 1em;
      background: $color-placeholder;
    }
  }

  .instructions {
    font-size: 14px;
    line-height: 1.25;
    margin-bottom: 30px;
    @include respond-to-sm {
      font-size: 18px;
      margin-bottom: 35px;
    }
    @include respond-to-md {
      margin-bottom: 40px;
    }
  }

  .faa-form {

    .form-group {
      @include clearfix;
      margin-bottom: 20px;
      position: relative;
    }

    label {
      display: block;
    }

    input {
      display: block;
      width: 80%;
      float: left;
      @include respond-to-sm {
        width: 23 * $grid-width-sm;
      }
      @include respond-to-md {
        width: 17 * $grid-width-md;
      }

    }

    .btn {
      @media (max-width: $screen-sm-min - 1) {
        width: 20%;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        float: left;
      }
      @include respond-to-sm {
        margin-left: 15px;
      }
    }

    .geolocate {
      font-size: 14px;
      line-height: 1.2;
      .fa-map-marker {
        color: $cyan;
      }
      a {
        color: $white !important;
        text-decoration: underline;
      }
    }
  }

}


.hero.bgimage,
.hero.campaign {
  padding-top: 50px;
  padding-right: ($outer-gutter-xs + (2 * $grid-width-xs));
  @include respond-to-sm {
    padding-right: ($outer-gutter-sm + (4 * $grid-width-sm));
  }
  @include respond-to-md {
    padding-right: ($outer-gutter-md + (6 * $grid-width-md));
  }
}


.hero.campaign {
  .inner {
    background-color: rgba(0,0,0,0.5);
  }
}


.hero.homepage {

  .inner {
    background: none !important;
    @include respond-to-sm {
      margin-left: 2 * $grid-width-sm;
    }
    @include respond-to-md {
      margin-left: 0;
    }
    .content:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: -30px;
      width: 6px;
      height: 100%;
      background: $color-placeholder;
    }
  }
}


@for $i from 1 through 8 {
  header.colorize-#{$i} {
    // background-color: nth($color-cycle, $i);

    .hero.bgimage .inner {
      background-color: nth($color-cycle, $i);
    }

    .hero .points li:before {
      background-color: nth($color-cycle-pair, $i);
    }

    .hero.homepage .content:before {
      background-color: nth($color-cycle-hero, $i);
    }


  }
}




