
@font-face {
  font-family: 'PublicoHeadline-Light';
  src: url('../fonts/PublicoHeadline-Light-Web.eot'); /* IE9 Compat Modes */
  src: url('../fonts/PublicoHeadline-Light-Web.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/PublicoHeadline-Light-Web.woff') format('woff'), /* Modern Browsers */
       url('../fonts/PublicoHeadline-Light-Web.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/PublicoHeadline-Light-Web.svg#5058f1af8388633f609cadb75a75dc9d') format('svg'); /* Legacy iOS */
  font-style:   normal;
  font-weight:  200;
}


@font-face {
  font-family: 'PublicoHeadline-Roman';
  src: url('../fonts/PublicoHeadline-Roman-Web.eot'); /* IE9 Compat Modes */
  src: url('../fonts/PublicoHeadline-Roman-Web.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/PublicoHeadline-Roman-Web.woff') format('woff'), /* Modern Browsers */
       url('../fonts/PublicoHeadline-Roman-Web.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/PublicoHeadline-Roman-Web.svg#5058f1af8388633f609cadb75a75dc9d') format('svg'); /* Legacy iOS */
  font-style:   normal;
  font-weight:  400;
}


@font-face {
  font-family: 'PublicoText-Roman';
  src: url('../fonts/PublicoText-Roman-Web.eot'); /* IE9 Compat Modes */
  src: url('../fonts/PublicoText-Roman-Web.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/PublicoText-Roman-Web.woff') format('woff'), /* Modern Browsers */
       url('../fonts/PublicoText-Roman-Web.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/PublicoText-Roman-Web.svg#5058f1af8388633f609cadb75a75dc9d') format('svg'); /* Legacy iOS */
  font-style:   normal;
  font-weight:  400;
}

