


.related-resources-promo {
  padding: 0 $outer-gutter-xs;
  @include respond-to-sm {
    padding: 0 ($outer-gutter-sm + (2*$grid-width-sm));
  }
  @include respond-to-md {
    padding: 0 ($outer-gutter-md + (2*$grid-width-md)) 0 ($outer-gutter-md + (10*$grid-width-md));
  }

  .items {
    margin: 40px 0;
    @include respond-to-sm {
      margin: 60px 0;
    }
  }

  .related-resources-promo-item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .tile {
    position: relative;
    background: $white;
    @include clearfix;

    .icon {
      position: relative;
      float: left;
      width: 88px;
      height: 88px;
      margin-right: $grid-width-xs;
      background: $color-placeholder;
      @include respond-to-sm {
        width: 138px;
        height: 138px;
        margin-right: $grid-width-sm;
      }
      @include respond-to-md {
        margin-right: $grid-width-md;
      }

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

    }

    .content {
    }


    .eyebrow {
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 15px;
    }

    .title {
      font: 18px/1.2 $font-heading2;
      margin-bottom: 15px;
      @include respond-to-sm {
        font-size: 24px;
      }
    }

    .text {
      font-size: 14px;
      line-height: 1.2;
      @include respond-to-sm {
        font-size: 16px;
      }
    }

    // .cta {
    //   display: block;
    //   position: absolute;
    //   top: 0; left: 0; right: 0; bottom: 0;
    //   @include hide-text;
    // }

  }
}



@for $i from 1 through 8 {
  .related-resources-promo-item.colorize-#{$i} {
    .icon { background-color: nth($color-cycle, $i); }
  }
}
