
.quote-extended {

  background-color: $lighter-gray;
  padding: 50px $outer-gutter-xs;
  @include respond-to-sm {
    padding: 90px $outer-gutter-sm 100px;
  }
  @include respond-to-md {
    padding: 90px ($outer-gutter-md + ($grid-width-md * 2)) 100px;
  }

  .content {
    @include respond-to-sm {
      padding-left: 2*$grid-width-md;
      border-left: 6px solid $color-placeholder;
    }
  }

  .text {
    font: 26px/1.1 $font-heading1;
    @include respond-to-sm {
      font-size: 28px;
      margin-bottom: 40px;
    }
  }

  .author {
    display: none;
    font-size: 15px;
    line-height: 1.1;
    @include respond-to-sm {
      display: block;
    }
  }
}


.quote-compact {

  padding: 50px $outer-gutter-xs;
  @include respond-to-sm {
    padding: 80px ($outer-gutter-sm);
  }
  @include respond-to-md {
    padding: 80px $outer-gutter-md 80px ($outer-gutter-md + 8*$grid-width-md);
  }

  .content {
    @include respond-to-sm {
      padding-left: 2*$grid-width-md;
      border-left: 6px solid $lighter-gray;
    }
  }

  .text {
    font: 26px/1.1 $font-heading1;
    @include respond-to-sm {
      font-size: 30px;
      margin-bottom: 40px;
    }
  }

  .author {
    display: none;
  }
}



@for $i from 1 through 8 {
  .quote-extended.colorize-#{$i} {
    .content { border-color: nth($color-cycle, $i); }
  }
}


