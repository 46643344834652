
.ribbon {
  font-size: 20px;
  line-height: 1;
  color: $white;
  background-color: $pink;
  padding: 15px $outer-gutter-xs 15px ($outer-gutter-xs + 2 * $grid-width-xs);
  @include respond-to-sm {
    font-size: 24px;
    padding: 23px $outer-gutter-sm 23px ($outer-gutter-sm + 2 * $grid-width-sm);
  }
  @include respond-to-md {
    padding: 23px $outer-gutter-md 23px ($outer-gutter-md + 2 * $grid-width-md);
  }
}
