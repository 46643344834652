
.in-page-nav {

  position: relative;
  height: 0;
  overflow: visible;
  display: none;

  @include respond-to-md {
    display: block;
  }

  nav {
    position: absolute;
    display: block;
    padding-top: 30px;
    top: 0;
    margin-top: 20px;
    // background: rgba(0,0,0,0.1);
    width: ($outer-gutter-md + 8*$grid-width-md);

    &.pinned {
      position: fixed;
      margin-top: 0;
      z-index: 10;
    }
  }

  .items {
    @include list-unstyled;
    margin: 0;
    background: $lighter-gray;
    position: relative;
    padding: 20px $outer-gutter-sm 50px;
  }

  .item {
    font-size: 18px;
    margin-bottom: 17px;

    a {
      display: inline-block;
      padding-bottom: 6px;
      border-bottom: 2px solid transparent;
      text-decoration: none !important;
      color: $dark-gray !important;
    }

    &.active a {
      border-bottom-color: $pink;
    }
  }
}




@for $i from 1 through 8 {
  .in-page-nav .item.colorize-#{$i}.active a {
    border-bottom-color: nth($color-cycle, $i);
  }
}