.productSubcategoryGroup {

  .tile {
    @include clearfix;
      padding-bottom: 50px;

  }

  .cat-sublinks, .copy {
    color: $text-color;
    padding: 40px $outer-gutter-xs;
  }

  .cat-sublinks { 
    margin: 0 $outer-gutter-xs 0 $outer-gutter-xs;
    padding: 0;
  }

  .cat-sublinks {
    ul { 
      margin: 0;
      padding: 0;
      font: 26px/1.2 $font-heading2;
      list-style: none;
      li {
      padding: $grid-gutter-width 0 0  $grid-gutter-width;
        i  {
          float:right;
          vertical-align: top;
          color:$btn-primary-color;
          margin-right: $outer-gutter-xs;
          font-size: 1.2em;
        }
      }
    }
  }
      
  .sublink {
    width:100%;
    height: 80px;
    padding: 0;
    border-bottom: 1px solid #bbb;
    a { 
      font-size:22px;
      text-decoration: none;
      color: $white;
      font-weight: 100;
    }
    a:hover {
        text-decoration: underline;
    }
  }

  .title {
    font: 26px/1.2 $font-heading2;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-light;
    @include respond-to-sm {
      font-size: 36px;
    }
  }

  .text {
    font-size: 14px;
    width: 60%;
  }

  .more-btn {
    margin: $outer-gutter-sm $outer-gutter-xs 0 $outer-gutter-xs;
  }

  @include respond-to-sm {
    // margin: 40px 0;
    .copy {
      float:left;
      width: 50%;
      padding-top: 100px;
      padding-right: 80px;
    };
    .cat-sublinks {
      float:right;
      padding: 0;
      margin: 60px 0 0 0;
      width: 50%; 
    };
    .tile {
      padding-bottom: 70px;
    }
  }
  @include respond-to-md {
    .copy {
      padding-right: 80px;

    }
    .cat-sublinks {
      margin: 80px 0 0 0;
    }
    .tile {
      padding-bottom: 100px;
    }
  }


  // Edit Mode - Remove for Production
  //@include edit;
  //background: rgba(0,0,0,.1) !important;
  //filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#10000000, endColorstr=#10000000);
}

@for $i from 1 through 8 {
  .productSubcategoryGroup.colorize-#{$i} {
    .cat-sublinks { background-color: nth($color-cycle, $i); }
  }
}