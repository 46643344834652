
.content-promo {
  background: $light-gray;
  padding: 40px 0;
  @include respond-to-sm {
    padding: 50px $outer-gutter-sm;
  }
  @include respond-to-md {
    padding: 60px ($outer-gutter-md + 2*$grid-width-md) ;
  }

  .items {
    @include make-row();
  }
}


.content-promo-item {
  margin-top: 40px;
  margin-bottom: 40px;
  @include respond-to-sm {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  @include make-sm-column(9);

  .tile {
    position: relative;
    background: $white;
    border-bottom: 4px solid $color-placeholder;
    @include clearfix;
    @include respond-to-sm {
      height: 540px;
    }
  }

  .image {
    position: relative;
    height: 160px;
    @include respond-to-sm { height: 215px; }
    background: transparent center center no-repeat;
    background-size: cover;

    .btn {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .text {
    overflow: hidden;
    padding: 0 (2*$grid-width-xs);
    @include respond-to-sm {
      padding: 0 ($grid-width-sm);
    }
    @include respond-to-md {
      padding: 0 ($grid-width-md);
    }

    > :first-child {
      margin-top: 30px;
      @include respond-to-sm { margin-top: 40px; }
    }

    .eyebrow {
      font: 14px/1.4 $font-heading2;
      margin-bottom: 20px;
    }

    .title {
      font: 24px/1.1 $font-heading1;
      @include respond-to-sm { font-size: 28px; }
      margin-bottom: 30px;
    }

    .description {
      font: 14px/1.3 $font-heading2;
      @include respond-to-sm { font-size: 16px; }
      margin-bottom: 40px;
    }

    .cta {
      margin-bottom: 40px;
    }
  }

}


.content-promo-item:first-child {
  margin-top: 0;
}


.content-promo-item:last-child {
  margin-bottom: 0;
}


.content-promo-item:first-child,
.content-promo-item:last-child:nth-child(2n) {

  @include make-sm-column(18);

  .tile {
    @include respond-to-sm { height: 250px; }
    @include respond-to-md { height: 300px; }
  }

  .image, .text {
    @include respond-to-sm {
      height: 100%;
      float: left;
    }
  }

  .image {
    @include respond-to-sm { width: 250px; }
    @include respond-to-md { width: 300px; }
  }
}


.content-promo-item.primary:first-child {

  .tile {

    background: $color-placeholder;
    color: $white;
    border-bottom: none;

    @include respond-to-sm { height: 290px; }
    @include respond-to-md { height: 350px; }

    .image {
      height: 300px;
      float: none;
      @include respond-to-sm {
        float: left;
        width: 50%;
        height: 100%;
      }
    }

    .text {
      float: none;
      @include respond-to-sm {
        float: left;
        width: 50%;
      }

      // > :first-child {
      //   @include respond-to-md { margin-top: 70px; }
      // }

      .eyebrow {
        font: 14px/1 $font-heading2;
        margin-bottom: 20px;
      }

      .title {
        font: 26px/1 $font-heading1;
        margin-bottom: 20px;
        @include respond-to-md {
          font-size: 34px;
          margin-bottom: 40px;
        }
      }

      .description {
        font: 14px/1.3 $font-heading2;
        margin-bottom: 30px;
      }

      .cta {
        color: $white !important;
        border-color: $white;
      }

    }
  }
}



@for $i from 1 through 8 {
  .content-promo-item.colorize-#{$i} {
    .tile { border-bottom-color: nth($color-cycle, $i); }
  }
  .content-promo-item.primary.colorize-#{$i}:first-child {
    .tile { background: nth($color-cycle, $i); }
  }
}


