
.intro-copy {

  margin: 40px $outer-gutter-xs;
  @include respond-to-sm {
    margin: 50px ($outer-gutter-sm);
  }
  @include respond-to-md {
    margin: 50px $outer-gutter-md 50px ($outer-gutter-md + 10*$grid-width-md);
  }

  .text {
    font: 16px/1.4 $font-heading2;
    @include respond-to-sm {
      font-size: 20px;
    }
  }
}



.body-copy {

  margin: 40px 0;
  padding: 0 $outer-gutter-xs;
  @include respond-to-sm {
    margin: 50px 0; // 40px;
    padding: 0 $outer-gutter-sm;
  }
  @include respond-to-md {
    margin: 50px 0; // 40px;
    padding: 0 $outer-gutter-md 0 ($outer-gutter-md + 10*$grid-width-md);
  }

  .heading {
    font-size: 24px;
    line-height: 1;
    padding-bottom: 20px;
    margin: 0 0 20px;
    @include respond-to-sm {
      margin-bottom: 30px;
    }
    border-bottom: 3px solid $color-placeholder;
  }

  .text {
    font-size: 14px;
    line-height: 1.3;
    @include respond-to-sm {
      font-size: 16px;
    }

    p, li {
      margin-bottom: 30px;
    }

    ul {
      margin-bottom: 0;
      padding-left: 1.5em;
      list-style-type: none;

    }

    ul li:before {
      content: '—';
      margin-right: 0.8em;
      margin-left: -1.5em;
    }
  }

  .text.cols {
    @include respond-to-sm {
      column-count: 2;
    }
  }
}

.body-copy-bg {
  position: relative;

  @include respond-to-sm {
    margin: 0;
    padding: 50px 0 0 ($outer-gutter-sm + 4*$grid-width-sm);
  }
  @include respond-to-md {
    padding: 50px 0 0 ($outer-gutter-md + 8*$grid-width-md);
  }

  .bg-image {
    display: none;
    @include respond-to-sm {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent center top no-repeat;
      background-size: 100% auto;
    }
  }

  .content {
    @include respond-to-sm {
      position: relative;
      background: $white;
      padding: 0 2*$grid-width-sm; // 40px;
      margin-bottom: 40px;
      &:before {
        content: '';
        display: block;
        height: 1px;
        margin-bottom: -1px;
      }
    }
    @include respond-to-md {
      padding: 0 2*$grid-width-md; // 40px;
    }
  }


}




@for $i from 1 through 8 {
  .body-copy .heading.colorize-#{$i} {
    border-color: nth($color-cycle, $i);
  }
}

