
footer {

  background: $mid-gray;
  padding: 40px $outer-gutter-xs;
  @include respond-to-sm {
    padding: 40px $outer-gutter-sm;
  }
  @include respond-to-md {
    padding: 40px $outer-gutter-md 40px ($outer-gutter-md + 2*$grid-width-md);
  }


  .country-selector {
    background-color: #fff;
    color: #000;
    height: 48px;
    padding-left: 20px;
    position: relative;
    float: none;
    margin-bottom: 20px;
    @include respond-to-sm {
      float: right;
      margin-bottom: 0;
    }
    height: 50px;
    width: 100%;
    @include respond-to-sm {
      width: 13 * $grid-width-sm;
    }
    @include respond-to-md {
      width: 13 * $grid-width-md;
    }
    font: 16px $font-heading1;
    color: $dark-gray;
    line-height: 50px;

    &:after {
      content: '';
      background: url('../img/vertical-ellipses.png') no-repeat scroll center center;
      display: block;
      height: 25px;
      position: absolute;
      right: 10px;
      top: 12px;
      width: 5px;
    }
  }



  .social-buttons {
    margin-bottom: 20px;
    @include respond-to-sm {
      margin-bottom: 50px;
    }
  }

  .copyright, .links {
    font-size: 11px;
    line-height: 1.4;
    @include respond-to-sm {
      font-size: 12px;
    }
    color: $gray;
    a {
      color: $gray !important;
      text-decoration: underline;
    }
  }

  .copyright {
    @include respond-to-sm {
      float: left;
      margin-right: 1.5em;
    }
  }

  .links {
    @include clearfix;
    margin-right: -0.75em;
    li {
      float: left;
      margin-right: 0.75em;
    }


  }

}
