

.social-buttons {

  @include clearfix;



}


.social-button {
  display: block;
  float: left;
  height: 50px;
  width: 50px;
  margin-right: 10px;
  background: $color-placeholder center center no-repeat;

  &.linkedin {
    background-image: url('../img/linkedin.png');
  }

  &.facebook {
    background-image: url('../img/facebook.png');
  }

  &.twitter {
    background-image: url('../img/twitter.png');
  }
}


